const config = {
  apiKey: "AIzaSyDanZ3mZS2FwwJbcdtTr-GWSza6zXIHpJg",
  authDomain: "pluto-psms.firebaseapp.com",
  databaseURL: "https://pluto-psms-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "pluto-psms",
  storageBucket: "pluto-psms.appspot.com",
  messagingSenderId: "51828385345",
  appId: "1:51828385345:web:aa688f285d7b96687088c0",
  measurementId: "G-JNZ8QTDFQH"
};

export default config;
