import { createTheme as createMUITheme } from "@mui/material/styles";

export default function createTheme() {
  return createMUITheme({
    palette: {
      primary: {
        main: "#E91E63"
      },
      secondary: {
        main: "#7C4DFF"
      }
    }
  });
}
