import { setContext } from "@apollo/client/link/context";
import cookies from "js-cookie";
import getCurrrentCompanyCode from "../url/getCurrentCompanyCode";

export default function createAuthLink() {
  return setContext((_, { headers }) => {
    const companyCode = getCurrrentCompanyCode();
    const shopId = cookies.get("shopId");
    const offlineSignInEmail = cookies.get("offlineSignInEmail");
    const headersToReturn = {
      ...headers,
      "x-company-code": companyCode,
      "x-shop-id": shopId,
      "x-timezone": Intl.DateTimeFormat().resolvedOptions().timeZone
    };

    if (offlineSignInEmail) {
      headersToReturn["x-offline-sign-in-email"] = offlineSignInEmail;
    }

    return {
      headers: headersToReturn
    };
  });
}
