import {
  getAuth,
  GoogleAuthProvider,
  onIdTokenChanged,
  signInWithPopup,
  signOut as signUserOut
} from "firebase/auth";
import getClient from "../libs/firebase/getClient";
import cookies from "js-cookie";
import { createContext, useContext, useEffect } from "react";
import { useGetConfigurationLazyQuery, User, Shop, Company, Config } from "../generated/graphql";
import PageLoader from "../components/PageLoader/PageLoader";

export type AuthContextValue = {
  user?: User;
  shop?: Shop;
  company?: Company;
  config?: Config;
  signOut: () => void;
  signIn: () => void;
};

const AuthContext = createContext<AuthContextValue>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  signOut: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  signIn: () => {}
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [getConfiguration, { loading, data }] = useGetConfigurationLazyQuery({
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    getClient();
    return onIdTokenChanged(getAuth(), async (fireUser) => {
      cookies.remove("token");
      if (!fireUser) {
        cookies.set("token", "", { sameSite: "strict", path: "/" });
      } else {
        const token = await fireUser.getIdToken();
        cookies.set("token", token, { sameSite: "strict", path: "/" });
      }
      await getConfiguration();
    });
  }, [getConfiguration]);

  useEffect(() => {
    const handle = setInterval(async () => {
      const user = getAuth().currentUser;
      if (user) {
        await user.getIdToken(true);
      }
    }, 15 * 60 * 1000);
    return () => clearInterval(handle);
  }, []);

  const auth = getAuth();
  const provider = new GoogleAuthProvider();

  const signOut = () => {
    signUserOut(getAuth());
    cookies.set("token", "", { sameSite: "strict", path: "/" });
  };

  const signIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      if (credential?.idToken) {
        cookies.set("token", credential.idToken, { sameSite: "strict", path: "/" });
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (loading || !data) {
    return <PageLoader center />;
  }

  const value = {
    user: data?.getCurrentUser,
    company: data?.getCurrentCompany,
    shop: data?.getCurrentShop,
    config: data?.getConfiguration,
    signOut,
    signIn
  } as AuthContextValue;

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
